<template>
  <section id="master-group-belanja">
    <b-row>
      <b-col cols="12">
        <template v-if="errorStat">
          <b-alert
            variant="danger"
            show
          >
            <h4 class="alert-heading">
              Error Found
            </h4>
            <div class="alert-body">
              {{ errorMsg }}
            </div>
          </b-alert>
        </template>
      </b-col>
      <b-col
        cols="4"
      >
        <b-card>
          <h5>Download Rekapitulasi Clearance</h5>
          <b-button
            variant="outline-primary"
            class="p-50"
            @click="downloadDRC"
          >
            <feather-icon
              icon="DownloadIcon"
              size="12"
            />
            Download .xlsx
          </b-button>
        </b-card>
      </b-col>
      <!-- post -->
    </b-row>
  </section>
</template>

<script>
import {
  BRow, BCol, BCard, BButton, BAlert,
} from 'bootstrap-vue'

import { getUserData } from '@/auth/utils'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BAlert,
  },
  data() {
    return {
      yearBudget: localStorage.getItem('tahunAnggaran'),
      userData: getUserData(),
      errorStat: false,
      errorMsg: '',
    }
  },
  methods: {
    downloadDRC() {
      document.getElementById('loading-bg').style.display = 'block'
      this.$http.post(`/report/download-rekapitulasi-clearance/${this.yearBudget}?token=${localStorage.getItem('userToken')}`)
        .then(res => {
          if (res.data.status === 'success') {
            const link = document.createElement('a')
            link.href = `${process.env.VUE_APP_API_URL}/document/excel/download/${res.data.data.filename}?token=${localStorage.getItem('userToken')}`
            link.click()
            document.getElementById('loading-bg').style.display = 'none'
          }
          return true
        })
        .catch(error => {
          this.errorStat = true
          this.errorMsg = error.response.data.error
          document.getElementById('loading-bg').style.display = 'none'
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
